import React from 'react';
import { useParams } from 'react-router-dom';
import { AppLayout } from './AppLayout';
import { orgSettingsModel } from './orgSettingsModel';
import { useOrgSettings } from './useOrgSettings';
import { OrgContextProvider } from './orgContext';
import { createTheme } from '../theme';
import { Box, ThemeProvider } from '@material-ui/core';

export function PageContainer({ renderPage }) {
  let { domainPath } = useParams();
  const { isPending, data, error } = useOrgSettings(domainPath);

  if (isPending) {
    return <Box display={'flex'} alignItems={'center'} > Loading...</Box>;
  }
  if (error) {
    console.log('error');
    return 'Error..';
  }
  if (data) {
    const model = orgSettingsModel(data, domainPath);
    const theme = createTheme(model.primaryColor);

    return (
      <OrgContextProvider>
        <ThemeProvider theme={theme}>
          <AppLayout orgSettings={data}>{renderPage(model)}</AppLayout>
        </ThemeProvider>
      </OrgContextProvider>
    );
  }
}
