import React from 'react';
import { Grid, Box } from '@material-ui/core';
import { Header } from './Header';
import { Footer } from './Footer';

export function AppLayout({ orgSettings, children }) {
  const { logoUrl } = orgSettings;
  return (
    <Grid
      container
      justify={'center'}
      alignItems={'flex-start'}
      alignContent={'flex-start'}
    >
      <Grid item xs={12}
      style={{ position: 'sticky', top: '0', zIndex:'1' }} >
        <Header logoUrl={logoUrl} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
        <Box>
          {children}
        </Box>
      </Grid>
      <Footer />
    </Grid>
  );
}
