const APPOINTMENT_TYPE = {
  STOREVISIT: 'LocationVisit',
  HOMEVISIT: 'HomeVisit',
  VIDEOCALL: 'VideoCall'
};

const APPOINTMENT_TYPE_QUERYSTRING_NAME = {
  [APPOINTMENT_TYPE.STOREVISIT]: 'isLocationVisitEnabled',
  [APPOINTMENT_TYPE.HOMEVISIT]: 'isHomeVisitEnabled',
  [APPOINTMENT_TYPE.VIDEOCALL]: 'isVideoCallEnabled'
};

const getAppointmentTypeLabels = (settings) => {
  return [
    {
      value: APPOINTMENT_TYPE.STOREVISIT,
      label: `${settings.locationSingularName} Visit`
    },
    { value: APPOINTMENT_TYPE.VIDEOCALL, label: 'Video Call' },
    { value: APPOINTMENT_TYPE.HOMEVISIT, label: 'Home Visit' }
  ];
};

export {
  APPOINTMENT_TYPE,
  APPOINTMENT_TYPE_QUERYSTRING_NAME,
  getAppointmentTypeLabels
};
