/* eslint-disable */
import React from 'react';
import {Box,Link, Typography } from '@material-ui/core';
import {makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    poweredBy: {
      fontWeight:'500',
      bottom: '0'
    },
    bold: {
        fontWeight:'600',  
    }
}));

export function Footer( ) {
    const classes = useStyles();
    return (
     <Box    
       display={'flex'}
       justifyContent={'center'}
       textAlign={'center'}
       bgcolor={'grey.100'}
       height={'auto'}
       width={'100%'}
       py={'10px'}
       position={'fixed'}
       boxShadow={'rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;'}
       className={classes.poweredBy}
       >
      <Typography variant={'caption'} color={'primary'}>
        {'Powered by '}      
        <Link href={'https://www.proappointments.io/'} variant={'caption'}
         underline={'hover'} 
         color={'primary'}
         className={classes.bold}
        >
          {'ProAppointments'}
        </Link >
      </Typography>
      </Box>
   );
}