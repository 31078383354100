import React from 'react';
import { AppContainer } from './components/AppContainer';
import { Box } from '@material-ui/core';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

function App() {
  return (
    <Box
      display={'flex'}
      justifyContent={'center'}
      textAlign={'center'}
      bgcolor={'grey.100'}
      minHeight={'100vh'}
    >
      <AppContainer />
    </Box>
  );
}

export default App;
