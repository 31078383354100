import { APPOINTMENT_TYPE } from './AppointmentType';

function orgSettingsModel(settings, domainPath) {
  const getSupportedAppointmentTypes = () => {
    const { isVideoCallEnabled, isLocationVisitEnabled, isHomeVisitEnabled } =
      settings;
    const supportedAppointmentTypes = [];

    if (isVideoCallEnabled) {
      supportedAppointmentTypes.push(APPOINTMENT_TYPE.VIDEOCALL);
    }
    if (isLocationVisitEnabled) {
      supportedAppointmentTypes.push(APPOINTMENT_TYPE.STOREVISIT);
    }
    if (isHomeVisitEnabled) {
      supportedAppointmentTypes.push(APPOINTMENT_TYPE.HOMEVISIT);
    }
    return supportedAppointmentTypes;
  };

  const getAdvanceBookingSettings = () => {
    const {
      videoMaximumAllowedAdvanceBooking,
      homeMaximumAllowedAdvanceBooking,
      locationMaximumAllowedAdvanceBooking
    } = settings;
    return {
      [APPOINTMENT_TYPE.STOREVISIT]: locationMaximumAllowedAdvanceBooking,
      [APPOINTMENT_TYPE.VIDEOCALL]: videoMaximumAllowedAdvanceBooking,
      [APPOINTMENT_TYPE.HOMEVISIT]: homeMaximumAllowedAdvanceBooking
    };
  };

  const {
    name,
    descriptionUrl,
    logoUrl,
    holidays,
    weeklyPlan,
    appointmentReasons,
    email,
    mobilePhone,
    terminology,
    address,
    countryCode,
    primaryColor,
    paymentGatewayType,
    locationMaximumAllowedSlots,
    showAppointmentReasonsOnWebsite
  } = settings;

  return {
    domainPath,
    name,
    address,
    descriptionUrl,
    logoUrl,
    appointmentReasons,
    email,
    mobilePhone,
    countryCode,
    paymentGatewayType,
    workingPlan: {
      holidays: holidays,
      weeklyPlan: weeklyPlan
    },
    locationSingularName: terminology.location.singular,
    customerSingularName: terminology.customer.singular,
    supportedAppointmentTypes: getSupportedAppointmentTypes(),
    getAdvanceBookingSettings,
    primaryColor,
    locationMaximumAllowedSlots,
    showAppointmentReasonsOnWebsite
  };
}

export { orgSettingsModel };
