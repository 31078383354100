import { useAsync } from 'react-async';
import { client } from '../utils/api-client';

export function useOrgSettings(domainPath) {
  return useAsync({ promiseFn: fetchSettings, domainPath: domainPath });
}

const fetchSettings = async ({ domainPath }) => {
  return client('organizations/me/light', {
    headers: { 'Pro-Appointments-Path': domainPath }
  });
};
