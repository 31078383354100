import { createMuiTheme } from '@material-ui/core/styles';
import invert from 'invert-color';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#7719AA'
    },
    appointmentStatus: {
      Requested: '#59ABE3',
      Created: '#70D005',
      Scheduled: '#008000',
      Rejected: '#F5751A',
      Cancelled: '#CB070D',
      InProgress: '#F7C206',
      Completed: '#555555'
    }
  }
});

const createTheme = (orgColor = '#7719AA') => {
  let root = document.documentElement;
  root.style.setProperty('--primary-color', orgColor);

  return createMuiTheme({
    palette: {
      primary: {
        main: orgColor,
        contrastText: invert(orgColor, true)
      },
      appointmentStatus: {
        Requested: '#59ABE3',
        Created: '#70D005',
        Scheduled: '#008000',
        Rejected: '#F5751A',
        Cancelled: '#CB070D',
        InProgress: '#F7C206',
        Completed: '#555555'
      }
    }
  });
};

export { theme, createTheme };
