import React from 'react';
import {Link } from '@material-ui/core';
import { useParams } from 'react-router-dom';

export function OrgLogo({ logoUrl }) {
  const { domainPath } = useParams();
  return (
    <Link href={"/" + domainPath} >
    <img
      width={'auto'}
      src={logoUrl}
      alt={'logo'}
      style={{ verticalAlign: 'middle', height:'auto', maxHeight:'100px' }}
    /></Link>
  );
}
