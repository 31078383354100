import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { OrgLogo } from './OrgLogo';

export function Header({ logoUrl }) {
  if (!logoUrl) {
    return null;
  }

  return (
    <Box
      padding={1}
      bgcolor="primary.main"
      color="primary.contrastText"
      height={'100%'}
    >
      <Grid item>
        <OrgLogo logoUrl={logoUrl} />
      </Grid>
    </Box>
  );
}
