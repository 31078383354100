import React, { Suspense } from 'react';
import { FullPageSpinner } from './FullPageSpinner';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import { PageContainer } from './PageContainer';

const HomePage = React.lazy(() => import('./HomePage'));

const AppointmentDetailsPage = React.lazy(() =>
  import('./AppointmentDetailsPage')
);

const AppointmentDetailsAdminPage = React.lazy(() =>
  import('./AppointmentDetailsAdminPage')
);

const AppointmentSchedulerPage = React.lazy(() =>
  import('./AppointmentSchedulerPage')
);

const PageNotFound = React.lazy(() => import('./PageNotFound'));
const WebSiteWrapper = React.lazy(() =>
  import(/* webpackPreload: true */ './WebSiteWrapper')
);
const PrivacyPolicy = React.lazy(() => import('./PrivacyPolicy'));
const TermsAndConditions = React.lazy(() => import('./TermsAndConditions'));

function AppContainer() {
  return (
    <Suspense fallback={<FullPageSpinner />}>
      <Router>
        <Switch>
          <Route path="/" exact>
            <WebSiteWrapper />
          </Route>
          <Route path="/privacypolicy" exact>
            <PrivacyPolicy />
          </Route>
          <Route path="/termsandconditions" exact>
            <TermsAndConditions />
          </Route>
          <Route path="/:domainPath" exact>
            <PageContainer
              renderPage={(model) => <HomePage orgSettings={model} />}
            />
          </Route>
          <Route path="/:domainPath/bookappointment" exact>
            <PageContainer
              renderPage={(model) => (
                <AppointmentSchedulerPage orgSettings={model} />
              )}
            />
          </Route>
          <Route path="/:domainPath/a/:appointmentId" exact>
            <PageContainer
              renderPage={(model) => (
                <AppointmentDetailsPage orgSettings={model} />
              )}
            />
          </Route>
          <Route path="/:domainPath/ha/:appointmentId" exact>
            <PageContainer
              renderPage={(model) => (
                <AppointmentDetailsAdminPage orgSettings={model} />
              )}
            />
          </Route>
          <Route path="*">
            <PageNotFound />
          </Route>
        </Switch>
      </Router>
    </Suspense>
  );
}

export { AppContainer };
