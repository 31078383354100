import React from 'react';
import { useParams } from 'react-router-dom';
import { client } from '../utils/api-client';

const OrgContext = React.createContext();
OrgContext.displayName = 'AuthContext';

export function OrgContextProvider(props) {
  const { domainPath } = useParams();

  const context = { domainPath };

  return <OrgContext.Provider value={context} {...props} />;
}

export function useOrgContext() {
  const context = React.useContext(OrgContext);
  if (context === undefined) {
    throw new Error(`useOrgContext must be used within a AuthProvider`);
  }
  return context;
}

export function useClient() {
  const { domainPath } = useOrgContext();

  return React.useCallback(
    (endpoint, config) => {
      const domainPathHeader = { 'Pro-Appointments-Path': domainPath };
      const newHeaders =
        config && config.headers
          ? { ...config.headers, ...domainPathHeader }
          : domainPathHeader;
      client(endpoint, { ...config, headers: newHeaders });
    },
    [domainPath]
  );
}
